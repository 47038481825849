import { createBrowserHistory } from 'history'

import config from './config'

const history = createBrowserHistory()

/**
 * Look at the URL string for param name and value configured in config.ts on `stickParams`.
 * Retain params and values across clicks and navigation calls.
 * @param urlString
 * @returns a URL string with the sticky params intact
 */
const appendStickyParam = (urlString: string) => {
  const sticky = config.stickyQueryParams[0]
  const targetParam = sticky.param // TODO parameterize this properly to enable multiple params
  const targetValue = sticky.value

  const value = new URLSearchParams(window.location.search).get(targetParam)

  if (value === targetValue) {
    const url = new URL(urlString, window.location.origin)

    if (!url.searchParams.has(targetParam)) {
      url.searchParams.set(targetParam, value)
    }

    return url.pathname + url.search + url.hash
  }

  return urlString
}

interface IHistoryPath {
  pathname: string
  search?: string
}

const originalPush = history.push
//@ts-ignore
history.push = (path: IHistoryPath, state) => {
  const newPath =
    typeof path === 'string'
      ? appendStickyParam(path)
      : appendStickyParam(path.pathname + (path.search || ''))
  originalPush(newPath, state)
}

const originalReplace = history.replace
//@ts-ignore
history.replace = (path: IHistoryPath, state) => {
  const newPath =
    typeof path === 'string'
      ? appendStickyParam(path)
      : appendStickyParam(path.pathname + (path.search || ''))
  originalReplace(newPath, state)
}

export default history
