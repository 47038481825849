import React from 'react'
import { Flex, Heading, Box } from 'rebass'
import { Switch } from 'react-router'
import { useRouteMatch, Link, Route } from 'react-router-dom'

const ClassResources: React.FC = () => {
  const match = useRouteMatch()

  const lessonPlans4_8 = [
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%204-8%20Lesson%20Plans/Jefferson%20Street%20Lesson%20Plan_Grades%204-8.pdf',
      anchorText: 'Jefferson Street Lesson Plan Grades 4-8',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%204-8%20Lesson%20Plans/East%20Nashville%20Lesson%20Plan_Grades%204-8.pdf',
      anchorText: 'East Nashville Lesson Plan Grades 4-8',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 4-8 Lesson Plans/Civil Rights Sit-Ins Lesson Plan_Grades 4-8.pdf',
      anchorText: 'Civil Rights Sit-Ins Lesson Plan Grades 4-8',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%204-8%20Lesson%20Plans/New%20South%20Lesson%20Plan_Grades%204-8.pdf',
      anchorText: 'New South Nashville Lesson Plan Grades 4-8',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 4-8 Lesson Plans/Downtown Civil War Lesson Plan_Grades 4-8.pdf',
      anchorText: 'Downtown Civil War Lesson Plan Grades 4-8',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 4-8 Lesson Plans/Early Black Life and Culture Lesson Plan_Grades 4-8.pdf',
      anchorText: 'Early Black Life and Culture Lesson Plan Grades 4-8',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 4-8 Lesson Plans/Fort Negley Lesson Plan_Grades 4-8.pdf',
      anchorText: 'Fort Negley Lesson Plan Grades 4-8',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 4-8 Lesson Plans/Public Arts and Murals Lesson Plan_Grades 4-8.pdf',
      anchorText: 'Public Arts and Murals Lesson Plan Grades 4-8',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 4-8 Lesson Plans/Woman_s Suffrage Lesson Plan_Grades 4-8.pdf',
      anchorText: "Woman's Suffrage Lesson Plan Grades 4-8",
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 4-8 Lesson Plans/Women_s History Highlights Lesson Plan_Grades 4-8.pdf',
      anchorText: "Women's History Highlights Lesson Plan Grades 4-8",
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%204-8%20Lesson%20Plans/Early%20History%20Lesson%20Plan_Grades%204-8.pdf',
      anchorText: 'Early History Lesson Plan Grades 4-8',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%204-8%20Lesson%20Plans/Gulch%20Highlights%20Lesson%20Plan_Grades%204-8.pdf',
      anchorText: 'Gulch Highlights Lesson Plan Grades 4-8',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%204-8%20Lesson%20Plans/Tennessee%20State%20University%20Lesson%20Plan_Grades%204-8.pdf',
      anchorText: 'Tennessee State University Lesson Plan Grades 4-8',
    },
  ]

  const lessonPlans9_12 = [
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%209-12%20Lesson%20Plans/Jefferson%20Street%20Lesson%20Plan_Grades%209-12.pdf',
      anchorText: 'Jefferson Street Lesson Plan Grades 9-12',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%209-12%20Lesson%20Plans/East%20Nashville%20Lesson%20Plan_Grades%209-12.pdf',
      anchorText: 'East Nashville Lesson Plan Grades 9-12',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 9-12 Lesson Plans/Civil Rights Sit-Ins Lesson Plan_Grades 9-12.pdf',
      anchorText: 'Civil Rights Sit-Ins Lesson Plan Grades 9-12',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%209-12%20Lesson%20Plans/New%20South%20Lesson%20Plan_Grades%209-12.pdf',
      anchorText: 'New South Nashville Lesson Plan Grades 9-12',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 9-12 Lesson Plans/Downtown Civil War Lesson Plan_Grades 9-12.pdf',
      anchorText: 'Downtown Civil War Lesson Plan Grades 9-12',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 9-12 Lesson Plans/Early Black Life and Culture Lesson Plan_Grades 9-12.pdf',
      anchorText: 'Early Black Life and Culture Lesson Plan Grades 9-12',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 9-12 Lesson Plans/Fort Negley Lesson Plan_Grades 9-12.pdf',
      anchorText: 'Fort Negley Lesson Plan Grades 9-12',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 9-12 Lesson Plans/Public Arts and Murals Lesson Plan_ Grades 9-12.pdf',
      anchorText: 'Public Arts and Murals Lesson Plan Grades 9-12',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%209-12%20Lesson%20Plans/Women_s%20History%20Highlights%20Lesson%20Plan_Grades%209-12.pdf',
      anchorText: "Women's History Lesson Plan Grades 9-12",
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades 9-12 Lesson Plans/Woman_s Suffrage Lesson Plan_Grades 9-12.pdf',
      anchorText: "Woman's Suffrage Lesson Plan Grades 9-12",
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%209-12%20Lesson%20Plans/Early%20History%20Lesson%20Plan_Grades%209-12.pdf',
      anchorText: 'Early History Lesson Plan Grades 9-12',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%209-12%20Lesson%20Plans/Gulch%20Highlights%20Lesson%20Plan_Grades%209-12.pdf',
      anchorText: 'Gulch Highlights Lesson Plan Grades 9-12',
    },
    {
      href:
        'https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Grades%209-12%20Lesson%20Plans/Tennessee%20State%20University%20Lesson%20Plan_Grades%209-12.pdf',
      anchorText: 'Tennessee State University Lesson Plan Grades 9-12',
    },
  ]

  return (
    <Flex
      mt={[0, 4]}
      px={[3]}
      pt={[0, 4]}
      pb={[4]}
      width={[1]}
      as="main"
      flexDirection="column"
      alignItems={['center']}
    >
      <Box width={[1, 600]}>
        <Heading mb={[3]} fontSize={[5]}>
          Lesson Plans
        </Heading>

        <Box>
          <a
            href="https://storage.googleapis.com/nashville-sites-prod.appspot.com/lesson-plans/Nashville%20Sites_How%20to%20use%20in%20the%20classroom.pdf"
            target="_blank"
            rel="noopener noreferrer"
          >
            How to use Nashville Sites in the classroom
          </a>
        </Box>

        <Box mt={[3]}>
          <Link to={`${match.url}/grades-4-8-lesson-plans`}>
            Grades 4-8 Lesson Plans
          </Link>
        </Box>

        <Box mt={[3]}>
          <Link to={`${match.url}/grades-9-12-lesson-plans`}>
            Grades 9-12 Lesson Plans
          </Link>
        </Box>

        <Box mt={[4]}>
          <Switch>
            <Route path={`${match.url}/grades-4-8-lesson-plans`}>
              <Heading> Grades 4 - 8 Lesson Plans </Heading>

              {lessonPlans4_8.map(lessonPlan => {
                return (
                  <Box key={lessonPlan.anchorText} mt={[3]}>
                    <a
                      target="_blank"
                      href={lessonPlan.href}
                      rel="noopener noreferrer"
                    >
                      {lessonPlan.anchorText}
                    </a>
                  </Box>
                )
              })}
            </Route>

            <Route path={`${match.url}/grades-9-12-lesson-plans`}>
              <Heading> Grades 9 - 12 Lesson Plans </Heading>

              {lessonPlans9_12.map(lessonPlan => {
                return (
                  <Box key={lessonPlan.anchorText} mt={[3]}>
                    <a
                      target="_blank"
                      href={lessonPlan.href}
                      rel="noopener noreferrer"
                    >
                      {lessonPlan.anchorText}
                    </a>
                  </Box>
                )
              })}
            </Route>
          </Switch>
        </Box>
      </Box>
    </Flex>
  )
}

export default ClassResources
