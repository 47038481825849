import { IQRCode, IRecord, IStop, ITour } from '../../../interfaces'

// STOP
const validateStop = (stop: Partial<IStop>): boolean => {
  return (
    !!stop.id &&
    !!stop.siteName &&
    !!stop.narrativeText &&
    !!stop.tourId &&
    !!stop.recordId &&
    !!stop.audio &&
    !!stop.images // && !!stop.content
  )
}

export function isStop(stop: IStop | Partial<IStop> | null): stop is IStop {
  return !!stop && validateStop(stop)
}
// END STOP

// RECORD

const hasTLD = (url: string): boolean => {
  const parts = url.split('.')
  return parts.length > 1 && parts[parts.length - 1].length >= 2
}

export const validateURL = (url: string): boolean => {
  try {
    const newUrl = new URL(url)
    return (
      (newUrl.protocol === 'http:' || newUrl.protocol === 'https:') &&
      hasTLD(newUrl.host)
    )
  } catch (e) {
    return false
  }
}

const validateRecord = (record: Partial<IRecord>): boolean => {
  return (
    typeof record.id === 'string' &&
    !!record.id && // Can't be empty string
    typeof record.Title === 'string' &&
    !!record.Title && // Can't be empty string
    typeof record.Type === 'string' &&
    !!record.Type && // Can't be empty string
    typeof record.slug === 'string' &&
    !!record.slug && // Can't be empty string
    typeof record.Creator === 'string' &&
    typeof record.Author === 'string' &&
    typeof record.Address === 'string' &&
    typeof record.Description === 'string' &&
    typeof record.Date === 'string' &&
    (!!record.RelationLink ? typeof record.RelationLink === 'string' : true) &&
    (!!record.RelationText ? typeof record.RelationText === 'string' : true) &&
    typeof record.Coverage === 'string' &&
    typeof record.Rights === 'string' &&
    typeof record.Notes === 'string' &&
    Array.isArray(record.Source) &&
    Array.isArray(record.Contributor) &&
    Array.isArray(record.Subject) &&
    Array.isArray(record.Keyword) &&
    Array.isArray(record.Tours) &&
    Array.isArray(record.audios) &&
    Array.isArray(record.videos) &&
    Array.isArray(record.images) &&
    // If we have Coordinates, we must have non-zero lat and lng
    (!!record.Coordinates
      ? !!record.Coordinates.lat && !!record.Coordinates.lng // Can't be 0
      : true)
  )
}

export function isRecord(
  record: IRecord | Partial<IRecord> | null
): record is IRecord {
  return !!record && validateRecord(record)
}
// END RECORD

// TOUR
const validateTour = (tour: Partial<ITour>): boolean => {
  return (
    typeof tour.id === 'string' &&
    !!tour.id &&
    typeof tour.title === 'string' &&
    !!tour.title &&
    typeof tour.description === 'string' &&
    !!tour.description &&
    typeof tour.intro === 'string' &&
    !!tour.intro &&
    typeof tour.outro === 'string' &&
    !!tour.outro &&
    typeof tour.duration === 'number' &&
    typeof tour.distance === 'number' &&
    typeof tour.map === 'string' &&
    !!tour.map &&
    Array.isArray(tour.images) &&
    Array.isArray(tour.stopIds)
  )
}

export function isTour(tour: ITour | Partial<ITour> | null): tour is ITour {
  return !!tour && validateTour(tour)
}
// END TOUR

// QR CODE
const validateQRCode = (qrCode: Partial<IQRCode>): boolean => {
  return (
    typeof qrCode.id === 'string' &&
    !!qrCode.id &&
    typeof qrCode.redirectUrl === 'string' &&
    !!qrCode.redirectUrl &&
    typeof qrCode.locationName === 'string' &&
    !!qrCode.locationName &&
    typeof qrCode.locationDescription === 'string' &&
    !!qrCode.locationDescription
  )
}

export function isQRCode(
  qrCode: IQRCode | Partial<IQRCode> | null
): qrCode is IQRCode {
  return !!qrCode && validateQRCode(qrCode)
}
// END QR CODE
