import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import { getStopsByKeywordIfNeeded } from '../../actions/stops'
import { AppState } from '../../reducers/rootReducer'
import { IStop, IStopsArrayDict, IToursDict } from '../../interfaces'
import { Box } from 'rebass'
import { Link } from 'react-router-dom'
import { event, EventAction, EventCategory } from '../../tracking'
import { getToursIfNeeded } from '../../actions/tours'

interface ISearchProps extends RouteComponentProps {
  stopsByKeyword: IStopsArrayDict
  toursById: IToursDict
  getStopsByKeywordIfNeeded: (keyword: string) => void
  getToursIfNeeded: () => void
}
const Search: React.FC<ISearchProps> = ({
  getStopsByKeywordIfNeeded,
  getToursIfNeeded,
  location,
  stopsByKeyword,
  toursById,
}) => {
  const params = new URLSearchParams(location.search)
  const keyword = params.get('keyword')
  const stopsForThisKeyword = stopsByKeyword[keyword || ''] || []

  useEffect(() => {
    if (keyword) {
      getToursIfNeeded()
      getStopsByKeywordIfNeeded(keyword)

      event({
        category: EventCategory.Search,
        action: EventAction.SearchExecuted,
        label: keyword,
      })
    }
  }, [keyword, getStopsByKeywordIfNeeded, getToursIfNeeded])

  if (keyword === null) {
    return <div>No search keyword</div>
  }

  if (!Object.keys(toursById).length) {
    return <p> waiting </p>
  }

  return (
    <Box px={3} pb={[3]}>
      <h1>Search Results</h1>
      <h2>Tour Stops</h2>
      <ul>
        {stopsForThisKeyword.map((stop: IStop) => {
          // const stopURL = `/tours/${stop.tourId}/${stop.id}`
          const tour = toursById[stop.tourId]
          const stopURL = `/tours/${tour.slug}/${stop.slug}`
          return (
            <li key={stop.id}>
              <Link to={stopURL}>{stop.siteName}</Link>
            </li>
          )
        })}
      </ul>
    </Box>
  )
}

export default connect(
  (state: AppState) => ({
    stopsByKeyword: state.entities.stops.byKeyword,
    toursById: state.entities.tours.byId,
  }),
  {
    getStopsByKeywordIfNeeded,
    getToursIfNeeded,
  }
)(withRouter(Search))
