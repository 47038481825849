const useInterceptor =
  process.env.REACT_APP_USE_AUTH_INTERCEPTER_TO_REFRESH_TOKEN === 'true'
    ? true
    : false

export default {
  API_URL: process.env.REACT_APP_API_URL || '',
  CMS_API_URL: process.env.REACT_APP_CMS_API_URL || '',
  GOOGLE_ANALYTICS_TRACKING_ID:
    process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || '',
  VERSION: process.env.REACT_APP_VERSION || 'NO_VERSION_SUPPLIED_FOR_BUILD',
  USE_INTERCEPTOR_TO_REFRESH_TOKEN: useInterceptor,
  stickyQueryParams: [{ param: 'c', value: 'musay' }],
}
