import { Box, Flex, Heading, Text, Link as RebassLink } from 'rebass'
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { connect } from 'react-redux'
import _ from 'lodash'

import { IAudio, IStopWithRecord, ITour } from '../../../interfaces'
import { setStopMode, openMap } from '../../../actions/ui'

import Audio from '../../common/Audio'
import ImageCarousel from '../../common/ImageCarousel'
import Button from '../../common/Button'
import withViewMore from '../../common/withViewMore'
import withCollapse from '../../common/withCollapse'

import Map from '../../map/Map'
import getNextStop from '../../../helpers/getNextStop'
import { event, EventAction, EventCategory } from '../../../tracking'

import TourStop from './TourStop'
import TourLandingPage from './TourLandingPage'
import TourStopsList from './TourStopsList'
import FullRecordAndCitation from './FullRecordAndCitation'
import OtherTours from './OtherTours'

import { notNull } from '../../../util'
import { useMusayParam } from '../../../hooks/useMusayParam'

const sendAnalyticsEventOnClick = () => {
  event({
    category: EventCategory.Donation,
    action: EventAction.ButtonClicked,
    label: `TourComplete`,
  })
}

const TourStopsWithCollapse = withCollapse(TourStopsList)
const FullRecordsWithCollapse = withCollapse(FullRecordAndCitation)
const OtherToursWithCollapse = withCollapse(OtherTours)

const validateCoordinates = (lat: number, lng: number): boolean => {
  // noinspection SuspiciousTypeOfGuard
  return (
    typeof lat === 'number' && typeof lng === 'number' && lat !== 0 && lng !== 0
  )
}

interface ITourContainerProps {
  isMapOpen: boolean
  allTours: { [Key: string]: ITour }
  tour: ITour
  stopsForTour: IStopWithRecord[]
  stop?: IStopWithRecord
  virtual: boolean
  isLanding: boolean
  setStopMode: (bool: boolean) => void
  openMap: (isMapOpen: boolean) => void
}
const TourContainer: React.FC<RouteComponentProps &
  ITourContainerProps> = props => {
  const {
    allTours,
    tour,
    stop,
    stopsForTour,
    history,
    isMapOpen,
    virtual,
    isLanding,
  } = props
  const [isTourStopsOpen, setIsTourStopsOpen] = useState(false)
  const [isFullRecordOpen, setIsFullRecordOpen] = useState(false)
  const [isOtherToursForSiteOpen, setIsOtherToursForSiteOpen] = useState(false)
  const [isOtherToursOpen, setIsOtherToursOpen] = useState(false)
  const [isTourOutro, setIsTourOutro] = useState(false)
  let allToursForSiteArray: ITour[] = [] // Only has items if this is a stop

  const hideOtherToursButton = useMusayParam()

  // TODO remove this goofy ass null check
  // data on zoom level prop expected to be sparse
  const nextStopMapZoomLevel = notNull(tour.nextStopMapZoomLevel)
    ? tour.nextStopMapZoomLevel
    : 17

  // If changing stop with back button or otherwise, we need to no longer show
  // the tour outro
  useEffect(() => {
    if (stop) {
      setIsTourOutro(false)
    }
  }, [stop, setIsTourOutro])

  let isLastStop = false
  const allToursArray = _.sortBy(Object.values(allTours), 'title')

  const handleOnStartTour = (tourToStart: ITour) => {
    event({
      category: EventCategory.Tour,
      action: EventAction.Started,
      label: tourToStart.title,
    })

    // TODO determine first stop slug (stopSlugs array instead of stopIds?)
    const firstId = tourToStart.stopIds[0]
    const firstTour = stopsForTour.find(tour => {
      return tour.id === firstId
    })
    const firstSlug = firstTour && firstTour.slug

    if (isLanding) {
      history.push(`/landing/walking-tours/back-to-start`)
    } else {
      history.push(`/tours/${tourToStart.slug}/${firstSlug}`)
    }
  }

  const handleOnStartVirtualTour = (tourToStart: ITour) => {
    event({
      category: EventCategory.Tour,
      action: EventAction.Started,
      label: tourToStart.title + '-virtual',
    })

    // TODO determine first stop slug (stopSlugs array instead of stopIds?)
    const firstId = tourToStart.stopIds[0]
    const firstTour = stopsForTour.find(tour => {
      return tour.id === firstId
    })
    const firstSlug = firstTour && firstTour.slug

    if (isLanding) {
      history.push(`/landing/walking-tours/back-to-start`)
    } else {
      history.push(`/tours/${tourToStart.slug}/v/${firstSlug}`)
    }
  }

  const handleGetDirections = () => {
    event({
      category: EventCategory.Tour,
      action: EventAction.GetDirectionsLinkClicked,
      label: tour.title,
    })

    const stopToDirectTo: IStopWithRecord = stopsForTour[0]
    if (stopToDirectTo.record.Coordinates) {
      const { lat, lng } = stopToDirectTo.record.Coordinates

      // If something is wrong with lat or lng, just open google maps.
      const googleMapsUrl = validateCoordinates(lat, lng)
        ? `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`
        : `https://www.google.com/maps/dir/?api=1`

      window.open(googleMapsUrl, '_blank')
    }
  }

  const handleOnNextStop = (nextStopSlug: string) => {
    event({
      category: EventCategory.Stop,
      action: EventAction.GoToNextStopClicked,
      label: stop && stop.siteName,
    })
    // For now, if there is not a next stop, just go to the tour landing page
    history.push(`/tours/${tour.slug}/${nextStopSlug || ''}`)
  }

  const handleNextStopOrOpenMap = () => {
    // if virtual, next stop
    if (virtual) {
      // TODO stop id to slug
      // TODO nextStop via closure, yay or nay?
      const nextSlug: string | null = nextStop && nextStop.slug
      handleOnNextVirtualStop(nextSlug)

      // else open map
    } else {
      props.openMap(true)
    }
  }

  // TODO stop id to slug
  const handleOnNextVirtualStop = (nextStopSlug: string | null) => {
    event({
      category: EventCategory.Stop,
      action: EventAction.GoToNextStopClicked,
      label: stop && stop.siteName,
    })

    // TODO stop id to slug
    // For now, if there is not a next stop, just go to the tour landing page
    history.push(`/tours/${tour.slug}/v/${nextStopSlug || ''}`)
  }

  const wasLegalAccepted = (): boolean => {
    return localStorage.getItem('tos') === 'true'
  }

  const setLegalWasAccepted = () => {
    localStorage.setItem('tos', 'true')
  }

  const handleLegalAccepted = () => {
    setLegalWasAccepted()
  }

  // Init audio, image, nextStop and stopMode. This is for tour mode.
  let audioToPlay: IAudio = tour.introAudio
  let nextStop: IStopWithRecord | null = stopsForTour[0]

  // If we are on a stop, set audio, image, nextStop, and stopMode
  if (stop) {
    const currentStopIndex = tour.stopIds.indexOf(stop.id)
    isLastStop = currentStopIndex === tour.stopIds.length - 1

    audioToPlay = stop.audio
    nextStop = getNextStop(stop.id, stopsForTour)

    allToursForSiteArray = _.sortBy(
      Object.values(allTours),
      'title'
    ).filter(t => stop.record.Tours.includes(t.id))
  }

  useEffect(() => {
    if (nextStop) {
      props.setStopMode(true)
    }

    // Unset stop mode when this component tears down
    return () => {
      props.setStopMode(false)
    }
  }, [nextStop, props])

  const handleCompleteTour = () => {
    event({
      category: EventCategory.Tour,
      action: EventAction.OutroViewed,
      label: tour.title,
    })

    event({
      category: EventCategory.Tour,
      action: EventAction.CompleteTourButtonClicked,
      label: tour.title,
    })
    setIsTourOutro(true)
  }

  const handleViewOtherTours = () => {
    history.push('/tours')
  }

  const handleTourStopsWithCollapseClick = () => {
    setIsTourStopsOpen(!isTourStopsOpen)
  }

  const handleFullRecordsWithCollapseClick = () => {
    event({
      category: EventCategory.Stop,
      action: EventAction.FullRecordAndCitationOpened,
      label: stop && stop.siteName,
    })
    setIsFullRecordOpen(!isFullRecordOpen)
  }

  const handleOtherToursForSiteWithCollapseClick = () => {
    setIsOtherToursForSiteOpen(!isOtherToursForSiteOpen)
  }

  const handleOtherToursWithCollapseClick = () => {
    setIsOtherToursOpen(!isOtherToursOpen)
  }

  if (!tour) {
    return <div>No tour found</div>
  }

  const OutroCopy = () => (
    <Box>
      <Text as="p" dangerouslySetInnerHTML={{ __html: tour.outro }} />
    </Box>
  )
  const OutroCopyWithViewMore = withViewMore(OutroCopy)

  return (
    <>
      <Flex pb={[4]} as="main" flexDirection="column" alignItems="center">
        <Box width={[1, 600]}>
          <Box width={[1, 600]} height={[270, 400]} mt={2}>
            <ImageCarousel images={stop ? stop.images : tour.images} />
          </Box>

          <Heading
            mx={3}
            mt={2}
            fontSize={[2]}
            fontWeight={600}
            color="lightGrey"
          >
            {tour.title}
          </Heading>

          {isTourOutro ? (
            <Box mx={3}>
              <Heading my={[4]} fontSize={[4]}>
                We hope you enjoyed this tour!
              </Heading>

              <OutroCopyWithViewMore />

              <Flex
                my={6}
                justifyContent="center"
                alignItems="center"
                flexDirection={['column']}
              >
                {hideOtherToursButton ? null : (
                  <Button mb={2} onClick={handleViewOtherTours}>
                    View Other Tours
                  </Button>
                )}

                <Text my={[3]} fontWeight={['bold']}>
                  Support Nashville Sites:
                </Text>

                <RebassLink
                  href="https://donorbox.org/support-nashville-sites"
                  onClick={sendAnalyticsEventOnClick}
                  target="_blank"
                  variant="goldButton"
                >
                  Donate Now!
                </RebassLink>

                <RebassLink
                  my={3}
                  href="https://www.bonfire.com/store/nashville-sites/"
                  onClick={sendAnalyticsEventOnClick}
                  target="_blank"
                  variant="goldButton"
                >
                  Merchandise
                </RebassLink>
              </Flex>
            </Box>
          ) : !stop ? (
            <TourLandingPage
              tour={tour}
              onStartTour={handleOnStartTour}
              onStartVirtualTour={handleOnStartVirtualTour}
              onGetDirections={handleGetDirections}
            />
          ) : (
            <>
              <TourStop
                tour={tour}
                stop={stop}
                onNext={handleNextStopOrOpenMap}
                onLegalAccepted={handleLegalAccepted}
                legalWasAccepted={wasLegalAccepted}
              />

              {isLastStop && (
                <Flex justifyContent="center">
                  <Button mt={4} mb={2} onClick={handleCompleteTour}>
                    Complete Tour
                  </Button>
                </Flex>
              )}
            </>
          )}

          <TourStopsWithCollapse
            tour={tour}
            stopsForTour={stopsForTour}
            currentStopId={stop && stop.id}
            title="Tour Stops"
            isOpen={isTourStopsOpen}
            onArrowClick={handleTourStopsWithCollapseClick}
            isVirtual={virtual}
          />

          {stop && (
            <FullRecordsWithCollapse
              record={stop.record}
              title="Full Record & Citation"
              isOpen={isFullRecordOpen}
              onArrowClick={handleFullRecordsWithCollapseClick}
            />
          )}

          {!!stop && !isLastStop && !!allToursForSiteArray.length && (
            <OtherToursWithCollapse
              tours={allToursForSiteArray}
              title="Other Tours for Site"
              isOpen={isOtherToursForSiteOpen}
              onArrowClick={handleOtherToursForSiteWithCollapseClick}
            />
          )}

          {isLastStop && (
            <OtherToursWithCollapse
              tours={allToursArray}
              title="Other Tours"
              isOpen={isOtherToursOpen}
              onArrowClick={handleOtherToursWithCollapseClick}
            />
          )}

          {isTourOutro
            ? tour.outroAudio && <Audio path={tour.outroAudio.path} />
            : audioToPlay && <Audio path={audioToPlay.path} />}
        </Box>
      </Flex>

      {nextStop && isMapOpen && (
        <Map
          nextStop={nextStop}
          onNextStop={handleOnNextStop}
          mapUrl={tour.map}
          zoomLevel={nextStopMapZoomLevel}
        />
      )}
    </>
  )
}

export default withRouter(
  connect(null, { setStopMode, openMap })(TourContainer)
)
