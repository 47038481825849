import { Box, Flex, Image } from 'rebass'
import Button from '../../common/Button'
import React, { useState } from 'react'
import { IImage, IMedia, isImage, MediaType } from '../../../interfaces'

import reorderArray, { Direction } from '../../../helpers/reorderArray'
import { ArrowLeft, ArrowRight } from '@material-ui/icons'
import Lightbox from 'react-image-lightbox'

const displayMedia = (
  type: MediaType,
  item: IMedia | IImage,
  onClick: () => void
) => {
  if (type === 'Image' && isImage(item)) {
    return (
      <Flex
        mx={3}
        sx={{ height: 100, width: 100 }}
        alignItems="center"
        justifyContent="center"
      >
        <Image
          src={item.path}
          alt={item.path}
          title={item.path}
          onClick={onClick}
          style={{
            height: 'auto',
            maxHeight: 100,
            width: 'auto',
            maxWidth: 100,
          }}
        />
      </Flex>
    )
  }

  if (type === 'Audio') {
    return <audio src={item.path} controls={true} />
  }

  if (type === 'Video') {
    return <video width="100%" src={item.path} controls={true} />
  }
}

interface IMediaListProps {
  type: MediaType
  media: IMedia[]
  onChange: (x: IMedia[]) => void
  onAddMedia: (type: MediaType) => void
  hideAddButton?: boolean
  hideOrderingControls?: boolean
}
const MediaList: React.FC<IMediaListProps> = ({
  type,
  media,
  onChange,
  onAddMedia,
  hideAddButton,
  hideOrderingControls,
}) => {
  const [selectedPhoto, setSelectedPhoto] = useState<number | null>(null)
  const [isEditMode, setIsEditMode] = useState(false)

  const photos = media as IImage[]

  const handleRemove = (i: number) => () => {
    const newMedia = [...media]
    newMedia.splice(i, 1)
    onChange(newMedia)
  }

  const handleReorder = (fromIndex: number, direction: Direction) => () => {
    onChange(reorderArray(media, fromIndex, direction))
  }

  const handleAdd = () => {
    onAddMedia(type)
  }

  const showCarousel = (i: number | null) => () => {
    setSelectedPhoto(i)
  }

  return (
    <>
      {selectedPhoto !== null && (
        <Lightbox
          mainSrc={photos[selectedPhoto].path}
          nextSrc={photos[(selectedPhoto + 1) % photos.length].path}
          prevSrc={
            photos[(selectedPhoto + photos.length - 1) % photos.length].path
          }
          onMovePrevRequest={() =>
            setSelectedPhoto(
              (selectedPhoto + photos.length - 1) % photos.length
            )
          }
          onMoveNextRequest={() =>
            setSelectedPhoto((selectedPhoto + 1) % photos.length)
          }
          onCloseRequest={() => setSelectedPhoto(null)}
          imageCaption={photos[selectedPhoto].caption || ''}
        />
      )}

      {/* Images have spacing of 3, so I conditionally remove spacing from the edges*/}
      <Flex flexWrap="wrap" mx={type === 'Image' ? -3 : 0}>
        {media.map((mediaItem, i) => {
          return (
            <Flex
              flexDirection="column"
              alignItems="center"
              my={3}
              key={i}
              sx={{ maxWidth: '100%' }}
            >
              {displayMedia(type, mediaItem, showCarousel(i))}

              {isEditMode ? (
                <Flex alignItems="center" mt={2}>
                  {!hideOrderingControls && (
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={handleReorder(i, Direction.Up)}
                    >
                      <ArrowLeft fontSize="large" />
                    </Box>
                  )}

                  <Button
                    mx={2}
                    color="red"
                    variant="outline"
                    onClick={handleRemove(i)}
                  >
                    Remove
                  </Button>

                  {!hideOrderingControls && (
                    <Box
                      sx={{ cursor: 'pointer' }}
                      onClick={handleReorder(i, Direction.Down)}
                    >
                      <ArrowRight fontSize="large" />
                    </Box>
                  )}
                </Flex>
              ) : null}
            </Flex>
          )
        })}
      </Flex>

      <Flex mt={2}>
        <Button
          variant="outline"
          color="blue"
          onClick={() => setIsEditMode(!isEditMode)}
        >
          {isEditMode ? 'Done' : 'Edit'}
        </Button>
        {!hideAddButton && (
          <Button ml={3} variant="primary" onClick={handleAdd}>
            Add {type}
          </Button>
        )}
      </Flex>
    </>
  )
}

export default MediaList
